body {
    font-family: "Roboto", sans-serif;
    color: #434343;
}

.internetbeschreibung p {
    margin: 0px;
}

.mw-18 {
    max-width: 19em;
}

.minheight-13 {
    height: 12.7rem;
}

.leaflet-container {
    height: 400px;
    width: 80%;
    margin: 0 auto;
}

.article:hover .article-name {
    color: rgb(4, 152, 154);
}

.circle {
    width: 7rem;
    height: 7rem;
}

.footer-header {
    font-size: 1.2rem;
    line-height: 120%;
    font-weight: 600;
    padding-bottom: 0.875rem;
}

input:required {
    box-shadow: none;
}

textarea:required {
    box-shadow: none;
}

label span {
    /* <-- style the artificial checkbox */
    height: 10px;
    width: 10px;
    border: 1px solid #434343;
    display: inline-block;
    position: relative;
}

.link:focus {
    outline: none !important;
}

.slick-prev {
    left: 0px !important;
}

.slick-next {
    right: 0px !important;
}

.slick-prev,
.slick-next {
    z-index: 1;
    width: 60px !important;
    height: 60px !important;
}

.slick-prev:before,
.slick-next:before {
    font-family: "Roboto" !important;
    font-weight: 600;
    color: #04989a !important;
    line-height: 0 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-next:before {
    content: "\203A" !important;
    font-size: 60px !important;
}

.slick-prev:before {
    content: "\02039" !important;
    font-size: 60px !important;
}

.slick-dots {
    bottom: 15px !important;
}

.slick-dots li button:before {
    font-size: 10px !important;
    color: #ccc !important;
}

.slick-dots li.slick-active button:before {
    color: #04989a !important;
}

[type="checkbox"]:checked + span:before {
    /* <-- style its checked state..with a ticked icon */
    content: "\25FC";
    color: #04989a;
    position: absolute;
    top: -5.5px;
    left: -2.5px;
    width: 2222px;
}

.text {
    text-align: justify;
    font-size: 14px;
    color: #626262;
}

.f-18 {
    font-size: 18px;
}

.f-26 {
    font-size: 26px;
}

.gray-color {
    color: #626262;
}

.text-header {
    font-size: 2.5rem;
    font-weight: 800;
    text-align: left;
}

.v-content h1 {
    font-size: 50px;
    font-weight: 800;
    text-align: left;

}

.w-bold {
    font-weight: bold;
}

/* 
.lh-120 {
    line-height: 120%;
} */

.justify-t-c {
    text-align: justify;
}

.g-2 {
    gap: 2rem;
}

.flex-1-1-100 {
    flex: 1 1 100%;
}

.search-input {
    display: flex;
    width: inherit;
}

.search-input input {
    outline: none;
}

.one-edge-shadow:hover {
    -webkit-box-shadow: 0px 8px 20px -6px #0000003d;
    -moz-box-shadow: 0px 8px 20px -6px #0000003d;
    box-shadow: 0px 8px 20px -6px #0000003d;
}

.bg-schuster {
    color: white;
    background-color: #04989a;
    border: 2px solid;
}

.btn-reset {
    border-style: solid;
    border-width: 1px;
    border-left: none;
    border-color: #767676;
}

.bg-schuster-inverted {
    color: #04989a;
    background-color: white;
    border: 2px solid;
    cursor: pointer;
}

.bg-green-schuster {
    background-color: #04989a;
}

.bg-gray-schuster {
    background-color: #626262;
}

.footer-hover:hover {
    text-decoration: underline white;
}

.App {
    text-align: center;
}

.hover-schuster-inverted-white:hover {
    color: #04989a;
    background-color: white;
    border: 2px solid;
}

.hover-schuster-inverted-green:hover {
    color: white;
    background-color: #04989a;
    border: 2px solid;
    cursor: pointer;
}

.hover-schuster:hover {
    color: #04989a;
}

.hover-schuster:focus {
    color: #04989a;
}

.App-logo {
    npm: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

.flex-nowrap {
    flex-wrap: flwx-nowrap;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.schuster-green {
    color: #04989a;
}

.schuster-light-green {
    color: #81bbbb;
}

.schuster-bg-green {
    background-color: #04989a;
}

.schuster-bg-light-green {
    background-color: #81bbbb;
}

.schuster-bg-gray {
    background-color: #eeeeee;
}

.b--schuster-green {
    border-color: #04989a;
}

.center-horizontal {
    margin-top: auto;
    margin-bottom: auto;
}

@media screen and (max-width: 60em) {
    .columns1 {
        column-width: auto;
        column-count: 1;
    }

    .block-none-m {
        display: block !important;
    }
}

@media screen and (max-width: 60em) {
    .slick-prev {
        display: none !important;
    }

    .slick-next {
        display: none !important;
    }

    .ma3 {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 60em) {
    .height-max {
        height: auto;
    }
}

@media screen and (min-width: 60em) {
    .height-99 {
        height: 99px;
    }
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.lang-list {
    list-style-type: none;
}

.lang-list li {
    display: inline-block;
    margin-right: 2rem;
    cursor: pointer;
}

.lang-list li:hover {
    color: #04989a;
}

.hamburger-btn {
    border: none;
    background-color: transparent;
}

.hamburger-btn img {
    width: 75%;
}

.link-decoration-style {
    text-decoration: none;
    color: #626262;
}

.link-decoration-style:hover {
    color: #04989a;
}